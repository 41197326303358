import { Route, Routes } from "react-router-dom";
import './App.css';
import Agent_chat from './components/pages/agent/Agent_chat';
import Agent_login from './components/pages/agent/Agent_login';
import Chat from './components/pages/chat/Chat';
import Select_agency from './components/pages/chat/Select_agency';

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Select_agency />} />
      <Route exact path="/chat" element={<Chat />} />
      <Route exact path="/chat/:agencyID/:userName" element={<Chat />} />
      <Route exact path="/agent-login" element={<Agent_login />} />
      <Route exact path="/agent-chat" element={<Agent_chat />} />
    </Routes>
  );
}

export default App;