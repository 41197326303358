import AbcIcon from '@mui/icons-material/Abc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Container, Grid, IconButton, Typography, } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import lock_icon from "../../assets/image/lock_icon.svg";
import login_logo from "../../assets/image/login_logo.svg";
import user_icon from "../../assets/image/user_icon.svg";

const Agent_login = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    // formik LoginSchema define
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .min(3, "Username must be 3 characters at minimum")
            .required("Username is required"),
        password: Yup.string()
            .min(3, "Password must be 3 characters at minimum")
            .required("Password is required")
    });

    //  login api call
    const loginDataSubmit = (data) => {
        const payload = {
            username: data.username,
            password: data.password,
        }
        axios.post(`${baseUrl}api/v1/login`, payload)
            .then(response => {
                localStorage.setItem('agent_login_token', response.data.data.token)
                navigate("/agent-chat");
            })
            .catch(error => {
                console.error(error);
            });
    }

    // custom input style
    const CustomTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: '#0B2B3B',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#0B2B3B',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#AFABAC',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0B2B3B',
            },
        },
        input: {
            height: '50px',
            padding: '0 0 0 5px'
        }
    });

    // page style
    const WebStyle = {
        agent_login_section: {
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(33, 85, 90, 0.06)'
        },
        login_in_part: {
            display: 'flex',
            height: '600px',
            borderRadius: '8px',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: "#fff",
            boxShadow: '0px 0px 18px 0px rgba(0, 0, 0, 0.10)',
        },
        login_grid_part: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignItems: 'center',
        },
        login_grid_part_inner: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignItems: 'center',
        },
        login_left_part: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: "8px 0 0 8px",
        },
        login_right_part: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: "100%"
        },
        agent_login_head_font: {
            fontSize: '30px',
            marginBottom: '20px'
        },
        form_filed: {
            width: '70%',
        },
        input_textfiled: {
            height: '50px',
            width: '100%',
            marginTop: '35px',
        },
        error_message: {
            color: 'red',
            marginTop: '5px',
            width: '100%',
        },
        login_button: {
            backgroundColor: '#0B2B3B',
            height: '48px',
            width: '100%',
            marginTop: '40px',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: '#1D4C51',
                color: '#fff',
            },
        }
    }

    return (
        <>
            <Box sx={WebStyle.agent_login_section}>
                <Container>
                    <Box sx={WebStyle.login_in_part}>
                        <Grid container sx={WebStyle.login_grid_part}>
                            <Grid item xs={6} sx={WebStyle.login_grid_part_inner}>
                                <Box sx={WebStyle.login_left_part}>
                                    <img className="img-fluid" src={login_logo} alt="login-image" />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={WebStyle.login_grid_part}>
                                <Box sx={WebStyle.login_right_part}>
                                    <Typography sx={WebStyle.agent_login_head_font}>Agent Login</Typography>
                                    <Box sx={WebStyle.form_filed}>
                                        <Formik
                                            initialValues={{ username: "", password: "" }}
                                            validationSchema={LoginSchema}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(false);
                                                loginDataSubmit(values)

                                            }}
                                        >
                                            {({ touched, errors, values, handleChange, handleBlur }) => (
                                                <Form>
                                                    <CustomTextField
                                                        id="username"
                                                        name="username"
                                                        label="User name" iconEnd={<AbcIcon />}
                                                        error={touched.username && errors.username}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start"><img src={user_icon} alt="icon" /></InputAdornment>
                                                            ),
                                                            style: {
                                                                height: "50px",
                                                                borderRadius: "8px"
                                                            },
                                                        }}
                                                        value={values.username}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        sx={WebStyle.input_textfiled}
                                                    />
                                                    {errors.username && touched.username && <Box sx={WebStyle.error_message}>{errors.username}</Box>}

                                                    <CustomTextField
                                                        id="password"
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        iconEnd={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}

                                                        label="Password" iconStart={<AbcIcon />}
                                                        error={touched.password && errors.password}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start"><img src={lock_icon} alt="iocn" /></InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleClickShowPassword} edge="end">
                                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                height: "50px",
                                                                borderRadius: "8px"
                                                            },
                                                        }}
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        sx={WebStyle.input_textfiled}
                                                    />
                                                    {errors.password && touched.password && <Box sx={WebStyle.error_message}>{errors.password}</Box>}

                                                    <Button type="submit" variant="contained" sx={WebStyle.login_button}>
                                                        Login
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

        </>
    )
}

export default Agent_login