import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import agency_select_no_image from '../../assets/image/agency_select_no_image.svg'
import arrow_left from '../../assets/image/arrow_left.svg'
import blockquiry_img from '../../assets/image/blockquiry_img.svg'
import search_icon from "../../assets/image/search_icon.svg"
import usa_image from '../../assets/image/usa_image.svg'
const randomAnimalName = require('random-animal-name')

const Select_agency = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const navigate = useNavigate();

    const animalName = useRef()

    const staticNameValue = randomAnimalName()

    const [isActiveId, setIsActiveId] = useState();
    const [headerMange, setHeaderMange] = useState(false);
    const [getAgencyData, setGetAgencyData] = useState([])
    const [agencyText, setAgencyText] = useState({
        agencyHeadText: '',
        agencyLocation: '',
        agencyImage: '',
        agencyID: '',
        userName: ''
    })

    // agency card click 
    const handleClick = (data) => {
        setHeaderMange(true)

        setIsActiveId(data._id)
        setAgencyText({
            agencyHeadText: data.label,
            agencyLocation: data.location,
            agencyImage: data.image,
            agencyID: data._id,
            userName: animalName.current,
        })
    };

    // start chat 
    const handleStartChat = () => {
        const data = {
            key1: agencyText.agencyID,
            key2: agencyText.userName,
        }; // Data to be passed through the URL
        const url = `/chat/${data.key1}/${data.key2}`; // Construct the URL with the data
        navigate(url); // Navigate to the other page
    }

    // page back 
    const handleBack = () => {
        navigate("/");
        setHeaderMange(false)
        setIsActiveId()
    }

    // Agency data get
    const getAgencyDataApi = () => {
        axios.get(`${baseUrl}api/v1/get/agency/list`)
            .then(response => {
                setGetAgencyData(response.data.data); // Store the API response in state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        getAgencyDataApi()
        animalName.current = staticNameValue
    }, [])

    // page style
    const WebStyle = {
        select_agency_section: {
            height: '100vh',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
        },
        left_part_card_section_main: {
            height: 'calc(100vh - 103px)',
            marginLeft: '5px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
        },
        select_agency_left_part: {
            minWidth: '350px',
            width: '350px',
            height: '100vh',
            borderRight: '1px solid rgba(0, 0, 0, 0.20)',
        },
        left_part_top_logo: {
            padding: '25px 0 26px 30px',
        },
        left_part_card_section: {
            display: 'flex',
            padding: '15px 30px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#EBEBEB',
                cursor: 'pointer',
            },
        },
        left_part_agency_name: {
            padding: '0 16px',
        },
        agencyName_text: {
            fontSize: '16px',
            color: 'rgba(11, 43, 59, 1)',
            fontWeight: '500',
        },
        agencySubName_text: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.4)'
        },
        select_agency_right_part: {
            width: '100%',

        },
        select_agency_search_bar: {
            height: 'calc(100vh - 70px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        right_part_header_image: {
            marginLeft: '30px',
            marginTop: '5px',
        },
        right_part_top_header: {
            height: '100px',
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        right_part_top_header_inner: {
            display: 'flex',
            alignItems: 'center',
        },
        right_part_agency_name: {
            padding: '0 16px',
        },
        right_part_bottom_part: {
            height: 'calc(100vh - 100px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        },
        right_part_bottom_inner: {
            width: 'auto',
            maxWidth: '850px',
            minWidth: '200px',
            margin: '0 20px',
        },
        right_part_bottom_username_font: {
            color: 'rgba(0, 0, 0, 1)',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '18px',
        },
        right_part_bottom_card: {
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0px',
            alignItems: 'center',
        },
        right_part_bottom_card_text: {
            padding: '0 16px',
        },
        text_right_part_card_agencySubName: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'start',
        },
        start_the_conversation_btn: {
            borderRadius: '6px',
            border: '1px solid #AFABAC',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            color: 'rgba(0, 0, 0, 1)',
            height: '46px',
            fontWeight: '600',
            textTransform: 'none',
            margin: '25px 0 35px 0 '
        },
        no_agency_select_section: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        no_agency_select: {
            fontSize: '30px',
            color: 'rgba(0, 0, 0, 0.4)',
            marginTop: '20px',
        }
    }

    return (
        <Box sx={WebStyle.select_agency_section}>

            <Box sx={WebStyle.right_part_top_header}>
                {!headerMange ?
                    <>
                        <Box sx={WebStyle.right_part_top_header_inner}>
                            <Box sx={WebStyle.right_part_header_image}>
                                <img src={blockquiry_img} alt="logo" />
                            </Box>
                        </Box>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }} className='select_agency_autocomplete'>


                            <Autocomplete
                                options={getAgencyData}
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
                                        borderColor: 'rgba(0, 0, 0, 0.2)',
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: 'none',
                                    },
                                }}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            borderRadius: '0 0 20px 20px ',
                                            "& .MuiAutocomplete-listbox::-webkit-scrollbar": {
                                                width: "4px",
                                                backgroundColor: "#f5f5f5"
                                            },
                                            "& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb": {
                                                backgroundColor: "#888",
                                                borderRadius: "4px"
                                            },
                                            "& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover": {
                                                backgroundColor: "#555"
                                            },
                                            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
                                        }
                                    }
                                }}
                                value={getAgencyData.value}
                                renderOption={(props, option) => (
                                    <Box sx={WebStyle.left_part_card_section} key={option._id} onClick={() => handleClick(option)}>
                                        <Box sx={WebStyle.left_part_card_image}>
                                            <img src={option.image} style={{ paddingTop: '5px', height: '35px', width: '50px', objectFit: 'cover' }} alt="logo" />
                                        </Box>
                                        <Box sx={WebStyle.left_part_agency_name}>
                                            <Typography sx={WebStyle.agencyName_text}>{option.label}</Typography>
                                            <Typography sx={WebStyle.agencySubName_text}>{option.location}</Typography>
                                        </Box>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" placeholder='Search agency'
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start"><img src={search_icon} alt="iocn" style={{ marginLeft: '16px' }} /></InputAdornment>
                                            ),
                                            sx: {
                                                borderRadius: 20,
                                                border: 'none',
                                                outline: 'none',
                                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                            }

                                        }}
                                    />
                                )}
                                style={{ width: '600px' }}
                            />
                        </Box>
                    </>
                    :
                    <>
                        <Box sx={WebStyle.right_part_top_header_inner}>
                            <Box style={{ marginLeft: '35px' }} onClick={() => { handleBack() }}>
                                <img src={arrow_left} style={{ cursor: 'pointer' }} />
                            </Box>
                            <Box sx={WebStyle.right_part_header_image}>
                                <img src={agencyText.agencyImage} alt="logo" style={{ borderRadius: '50%', height: '40px', width: '40px', objectFit: 'cover' }} />
                            </Box>
                            <Box sx={WebStyle.right_part_agency_name}>
                                <Typography sx={WebStyle.agencyName_text}>{agencyText.agencyHeadText}</Typography>
                                <Typography sx={WebStyle.agencySubName_text}>{agencyText.agencyLocation}</Typography>
                            </Box>
                        </Box>
                    </>}

            </Box>
            <Box sx={WebStyle.select_agency_search_bar}>
                {isActiveId ?
                    <Box sx={WebStyle.select_agency_right_part}>
                        <Box sx={WebStyle.right_part_bottom_part}>
                            <Box sx={WebStyle.right_part_bottom_inner}>
                                <Typography sx={WebStyle.right_part_bottom_username_font} >Your username: <span style={{ color: 'rgba(246, 126, 42, 1)', fontSize: '20px', fontWeight: '600' }}>{animalName.current}</span></Typography>
                                <Typography style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '18px' }}>You are about to initiate an anonymous conversation with:</Typography>
                                <Box sx={WebStyle.right_part_bottom_card}>
                                    <Box >
                                        <img src={agencyText.agencyImage} style={{ borderRadius: '50%', height: '40px', width: '40px', objectFit: 'cover' }} alt="logo" />
                                    </Box>
                                    <Box sx={WebStyle.right_part_bottom_card_text}>
                                        <Typography sx={WebStyle.agencyName_text}>{agencyText.agencyHeadText}</Typography>
                                        <Typography sx={WebStyle.text_right_part_card_agencySubName}>{agencyText.agencyLocation}</Typography>
                                    </Box>
                                </Box>
                                <Button variant="outlined" sx={WebStyle.start_the_conversation_btn} onClick={() => { handleStartChat() }}>Start the conversation</Button>
                                <Typography style={{ color: 'rgba(153, 153, 153, 1)', fontSize: '16px', marginBottom: '25px' }} textAlign="start">Before commencing the conversation with the <span style={{ color: 'rgba(11, 43, 59, 1)', fontWeight: '600' }}>{agencyText.agencyHeadText}</span> Please note the following:</Typography>
                                <ul style={{ textAlign: 'start', color: 'rgba(153, 153, 153, 1)' }}>
                                    <li>All interactions between you and HSCO are encrypted to ensure privacy and security.</li>
                                    <li>Your identity remains anonymous to both blockquiry and HCSO.</li>
                                    <li>HCSO will address you using you the randomly generated user name <span style={{ color: 'rgba(11, 43, 59, 1)', fontWeight: '600' }}>{animalName.current} </span></li>
                                    <li>Once you conclude the conversation or exit your browser, no records of the conversation will be retained.</li>
                                </ul>
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box sx={WebStyle.no_agency_select_section}>
                        <img src={agency_select_no_image} alt="no select logo" />
                        <Typography sx={WebStyle.no_agency_select}>Please Agency Select</Typography>
                    </Box>
                }
            </Box>
        </Box >
    )
}

export default Select_agency