import { Box, Button, TextField, Typography } from '@mui/material';
import { AES, enc } from 'crypto-js';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSend } from "react-icons/ai";
import io from 'socket.io-client';

const Agent_chat = () => {
    const cryptoSecretKey = process.env.REACT_APP_CRYPTO_SECRET;

    const messageEl = useRef(null);

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [socketAuth, setSocketAuth] = useState(null);
    const [userName, setUsername] = useState();

    useEffect(() => {
        handleConnect()
    }, []);

    useEffect(() => {
        if (socketAuth) {
            const secretKey = cryptoSecretKey; // Secret key for encryption

            socketAuth.on('getMessage', (data) => {
                if (data.id) {
                    const bytes = AES.decrypt(data.message, secretKey);
                    let decryptedText = bytes.toString(enc.Utf8);
                    const newData = {
                        id: data.id,
                        isAgent: data.isAgent,
                        message: decryptedText,
                        name: data.name
                    }
                    setUsername(newData.name)
                    setMessages((prevMessages) => prevMessages.length === 0 ? [data] : [...prevMessages, newData]);
                } else {
                    const newData = {
                        id: data.id,
                        isAgent: data.isAgent,
                        message: data.message,
                        name: data.name
                    }
                    setMessages((prevMessages) => prevMessages.length === 0 ? [data] : [...prevMessages, newData]);
                }
                if (data.code == 1006) {
                    handleConnect()
                }
            });
        }
    }, [socketAuth])

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [])

    // socket connection
    const handleConnect = () => {
        const socketUrl = process.env.REACT_APP_SOCKET_URL
        const socket = io(socketUrl, {
            extraHeaders: { Authorization: localStorage.getItem('agent_login_token') }
        });
        setSocketAuth(socket)
    }

    const handleInputChange = (e) => {
        e.preventDefault();
        setInputValue(e.target.value);
    };

    // agent send messages
    const handleSendMessage = () => {
        if (inputValue.trim() !== '') {
            const secretKey = cryptoSecretKey; // Secret key for encryption
            const encryptionText = AES.encrypt(inputValue, secretKey).toString();

            const eventDataSend = {
                id: messages.length + 1,
                isAgent: 1,
                message: encryptionText,
            };
            const eventData = {
                id: messages.length + 1,
                isAgent: 1,
                message: inputValue,
            };
            if (socketAuth) {
                socketAuth.emit('sendMessage', eventDataSend);
                setMessages((prevMessages) => prevMessages.length === 0 ? [eventData] : [...prevMessages, eventData]);
            }

            setInputValue('');
        }

    };

    // for sending message by pressing enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    // page style
    const WebStyle = {
        agent_chat_section: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            backgroundColor: 'rgba(33, 85, 90, 0.06)',
        },
        agent_chat_part: {
            display: 'flex',
            justifyContent: 'center',
        },
        agent_chat_all_message: {
            backgroundColor: '#fff',
            width: '100%',
        },
        agent_chat_all_message_head: {
            padding: '0 30px',
        },
        top_header_image_section: {
            height: '40px',
            width: '40px',
            backgroundColor: 'rgba(162, 160, 239, 1)',
            borderRadius: '50px',
            marginLeft: '35px',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
        },
        top_header_section: {
            height: '70px',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
            display: 'flex',
            alignItems: 'center',
        },
        agent_chat_message_inner: {
            height: 'calc(100vh - 166px)',
            overflow: 'auto',
            paddingTop: '10px'
        },
        messages_agent_true: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
        },
        messages_agent_false: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
        },
        chat_message_received: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            maxWidth: '325px',
            width: 'fit-content',
            wordBreak: 'break-word',
            borderRadius: '0px 10px 10px 10px',
            margin: '2px',
            color: 'rgba(0, 0, 0, 1)',
            fontWeight: '500',
            padding: '8px 24px 8px 14px',
        },
        chat_message_send: {
            borderRadius: '10px 0px 10px 10px',
            backgroundColor: 'rgba(11, 43, 59, 0.2)',
            maxWidth: '325px',
            width: 'fit-content',
            wordBreak: 'break-word',
            margin: '2px',
            color: '#0B2B3B',
            fontWeight: '500',
            padding: '8px 14px 8px 24px',
        },
        message_font: {
            fontSize: '18px',
        },
        message_user_name: {
            fontSize: '16px',
            color: 'rgba(109, 110, 111, 0.4)',
        },
        message_float_right: {
            float: 'right',
        },
        message_float_left: {
            float: 'left',
        },
        input_button_main: {
            display: 'flex',
            width: '100%',
        },
        message_input: {
            width: '100%',
            marginBottom: '30px',
        },
        send_btn: {
            borderRadius: '10px',
            backgroundColor: 'rgba(11, 43, 59, 1)',
            color: '#fff',
            fontSize: '18px',
            marginBottom: '30px',
            marginLeft: '16px',
            padding: '0px 20px',
            '&:hover': {
                backgroundColor: '#1D4C51',
                color: '#fff',
            },
        }
    }

    return (
        <Box sx={WebStyle.agent_chat_section}>
            <Box sx={WebStyle.agent_chat_part}>
                <Box sx={WebStyle.agent_chat_all_message}>
                    <Box sx={WebStyle.top_header_section}>
                        {userName ? <Box sx={WebStyle.top_header_image_section}>{userName?.charAt(0)}</Box> : ''}
                        <Typography style={{ fontSize: '24px', fontWeight: '500', color: 'rgba(11, 43, 59, 1)', paddingLeft: '16px' }}>{userName}</Typography>
                    </Box>
                    <Box sx={WebStyle.agent_chat_all_message_head}>
                        <Box sx={WebStyle.agent_chat_message_inner} ref={messageEl}>
                            {messages.map((message) => (
                                <Box sx={message.isAgent == 0 ? WebStyle.messages_agent_true : WebStyle.messages_agent_false}>
                                    <Box sx={message.isAgent == 0 ? WebStyle.chat_message_received : WebStyle.chat_message_send} key={message.id}>
                                        <Typography sx={WebStyle.message_font}>{message.message}</Typography>
                                    </Box>
                                    <Typography sx={message.isAgent == 0 ? [WebStyle.message_user_name, WebStyle.message_float_left] : [WebStyle.message_user_name, WebStyle.message_float_right]}>{message.name}</Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={WebStyle.input_button_main}>
                            <TextField
                                type='text'
                                label="Message"
                                variant="outlined"
                                value={inputValue}
                                onChange={(e) => { handleInputChange(e) }}
                                onKeyPress={handleKeyPress}
                                InputProps={{
                                    style: {
                                        borderRadius: "10px"
                                    },
                                }}
                                sx={{
                                    ...WebStyle.message_input,
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#0B2B3B', // Change the border color when focused
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#0B2B3B', // Change the label color when focused
                                    },
                                    '& .MuiInputLabel-outlined.Mui-focused': {
                                        color: '#0B2B3B', // Change the outline variant name color when focused
                                    },
                                }}
                            />
                            <Button sx={WebStyle.send_btn} onClick={() => handleSendMessage()}>SEND<AiOutlineSend size={30} style={{ marginLeft: '10px' }} /></Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >

    )
}

export default Agent_chat